import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SalesPage = () => {
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      const res = await fetch(
        "https://c5lnpw1jcj.execute-api.us-east-1.amazonaws.com/prod/join-waitlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            phone: `${countryCode}${phone}`,
          }),
        }
      );

      if (!res.ok) {
        throw new Error("Failed to join waitlist.");
      }

      setSuccess(true);
      setEmail("");
      setPhone("");
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center gap-6 w-full max-w-sm"
      >
        <h1 className="text-6xl font-bold">Clarity</h1>
        <p className="text-xl text-center">
          The calendar app that maximizes the time you're in flow.
        </p>

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-4 !h-[48px] bg-zinc-900 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white border border-zinc-800 focus:border-white"
        />

        <PhoneInput
          country={"us"}
          value={phone}
          onChange={setPhone}
          inputClass="!bg-zinc-900 !text-white !placeholder-gray-400 !w-full !pl-16 !pr-4 !rounded-md !border !border-zinc-800 focus:!border-white focus:!ring-2 focus:!ring-white !h-[48px] !leading-tight"
          buttonClass="!bg-zinc-900 !border-none !left-4 !top-1/2 !-translate-y-1/2 !h-5"
          dropdownClass="!bg-zinc-900 !text-white"
          containerClass="w-full"
        />

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-zinc-100 text-black font-medium py-3 rounded-md hover:bg-white transition disabled:opacity-50"
        >
          {loading ? "Joining..." : "Join Waitlist"}
        </button>

        {success && <p className="text-green-400">You're on the waitlist!</p>}
        {error && <p className="text-red-400">{error}</p>}
      </form>

      {/* <div className="mt-12 flex gap-6 text-sm text-zinc-400">
        <a href="#" className="hover:text-white transition">
          Read our thesis →
        </a>
        <a href="#" className="hover:text-white transition">
          Follow 10x →
        </a>
        <a href="#" className="hover:text-white transition">
          Join our Discord →
        </a>
        <a href="#" className="hover:text-white transition">
          Email support →
        </a>
      </div> */}
    </div>
  );
};

export default SalesPage;
